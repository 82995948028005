<template>
  <common-default-layout :spring-desktop-size="100" is-aw-spring :small-header="smallHeader">
    <template v-if="$slots.categoryMenu?.()?.[0]?.children?.length" #categoryMenu />
    <template #content="{ defaultLayoutClasses }">
      <slot name="content" :default-layout-classes="defaultLayoutClasses">
        <div :class="[defaultLayoutClasses.container]">
          <component :is="springComponent" v-bind="springAttributes">
            <slot />
          </component>
        </div>
      </slot>
    </template>
    <template #modal>
      <aw-expire-timeframe-modal />
      <aw-gdpr-acceptance-modal :key="`gdpr-acceptance-modal-${isGdprOpened}`" />
      <aw-static-content-modal />
      <aw-profile-modal />
      <aw-cart-merge-modal :key="`cart-merge-modal-modal-${isCartMergeOpened}`" />
      <aw-delivery-method-modal :key="`delivery-modal-${isDeliveryMethodOpened}`" />
      <aw-startup-popup />
    </template>
  </common-default-layout>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { mapActions, mapState } from 'pinia';
  import { navigateTo, useNuxtApp } from 'nuxt/app';
  import { M_GDPR, M_DELIVERY_METHOD, M_CART_MERGE } from '~~/common/config/Modal.js';
  import CommonDefaultLayout from '~~/common/layouts/default';
  import AwStartupPopup from '~~/shop/components/Common/Modals/AwStartupPopup';
  import { commitAlcoholAlertModal, commitAlcoholConfirmModal } from '~~/common/utils/alcoholManagement';
  import { setErrorNotification } from '~~/common/utils/helper';
  import { useCookieConsentStore } from '~~/shop/stores/cookieConsent';

  import { useUrlResourceStore } from '~~/common/stores/urlResource';
  import { useModalStore } from '~~/common/stores/modal';
  import { useOrderStore } from '~~/common/stores/order';
  import { useCategoryStore } from '~~/common/stores/category';
  import { useConfigStore } from '~~/common/stores/config';
  import { useProductsStore } from '~~/common/stores/products';
  import { useProductStore } from '~~/common/stores/product';
  import { useUserStore } from '~~/common/stores/user';
  import { useDeliveryStore } from '~~/shop/stores/delivery';
  import { LvSpring } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwSpring from '~~/common/components/AwSpring';
  import pageTypeMixin from '~~/common/mixins/pageTypeMixin.js';

  export default {
    name: 'DynamicContentLayout',
    components: {
      AwCartMergeModal: defineAsyncComponent(() => import('~~/common/components/Common/Modals/AwCartMergeModal')),
      AwProfileModal: defineAsyncComponent(() => import('~~/shop/components/Common/Modals/AwProfileModal')),
      AwGdprAcceptanceModal: defineAsyncComponent(() => import('~~/shop/components/Common/Modals/AwGdprAcceptanceModal')),
      AwStaticContentModal: defineAsyncComponent(() => import('~~/shop/components/Common/Modals/AwStaticContentModal')),
      AwExpireTimeframeModal: defineAsyncComponent(() => import('~~/shop/components/Common/Modals/AwExpireTimeframeModal')),
      CommonDefaultLayout,
      AwDeliveryMethodModal: defineAsyncComponent(() => import('~~/shop/components/Common/Modals/AwDeliveryMethodAndTimeframeModal')),
      AwStartupPopup,
      AwSpring,
      LvSpring,
    },
    mixins: [pageTypeMixin],
    props: {
      springSize: {
        type: [String, Boolean],
        default: null,
      },
      smallHeader: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        pageLoader: true,
      };
    },
    computed: {
      ...mapState(useUserStore, {
        user: state => state.data,
        isEcomUser: state => state.isEcomUser,
        showAlcoholAlertModalBeforeLogIn: state => state.showAlcoholAlertModalBeforeLogIn,
        userAgeConfirmed: state => state.data?.personalInfo?.ageConfirmed,
      }),
      ...mapState(useConfigStore, {
        startPopup: state => state.configCommon?.startPopup,
      }),
      ...mapState(useCategoryStore, {
        getCategoryFromTree: state => state.getCategoryFromTree,
      }),
      ...mapState(useUrlResourceStore, {
        calculatedSpringDesktopSize: state => state.data.resourceType === 'cms_content' || state.data.resourceType === 'catalog' ? 100 : null,
        resourceType: state => state.data.resourceType,
      }),
      ...mapState(useProductsStore, {
        containsAgeRestrictedProduct: state => state.containsAgeRestrictedProduct,
      }),
      ...mapState(useModalStore, {
        confirmModal: state => state.modals.confirmModal,
        isCartMergeOpened: state => state.activeGenericModal.type === M_CART_MERGE,
        isDeliveryMethodOpened: state => state.activeGenericModal.type === M_DELIVERY_METHOD,
        isGdprOpened: state => state.activeGenericModal.type === M_GDPR,
      }),
      ...mapState(useProductStore, {
        currentPageContainAgeRestrictedProduct (state) {
          return (this.containsAgeRestrictedProduct && this.isProductListPage) || // we are on product list page
            (this.containsAgeRestrictedProduct && this.isSearchPage) || // we are on search page
            (state.data?.adultsOnly && this.isProductDetailsPage) // we are on product page
          ;
        },
      }),
      ...mapState(useDeliveryStore, {
        deliverySetup: state => state.setup,
      }),
      calculatedSpringSize () {
        if (this.springSize) {
          return this.springSize;
        }
        if (this.calculatedSpringDesktopSize) {
          return null;
        }
        return 'md';
      },
      springComponent () {
        const containsProductListPage = this.isProductListPage || this.isSearchPage || this.isBoutique || this.isShortList || this.isLoyaltyOffer || this.isPreviouslyOrderedProducts;
        return !this.calculatedSpringSize || containsProductListPage || this.resourceType === 'catalog' || this.isFaqPage || this.isProductDetailsPage ? AwSpring : LvSpring;
      },
      springAttributes () {
        return !this.calculatedSpringSize
          ? {
            desktopSize: this.calculatedSpringDesktopSize,
          }
          : {
            size: this.calculatedSpringSize,
          }
        ;
      },
    },
    watch: {
      isEcomUser (newValue) {
        if (newValue) {
          if (this.showAlcoholAlertModalBeforeLogIn && this.userAgeConfirmed == null) {
            commitAlcoholConfirmModal(this.$awt);
            useUserStore().setShowAlcoholAlertModalBeforeLogIn(false);
          }
        }
      },
      // check delivery area is changed -> if changed, then save laterOpen flag. If close modal we check this.
      deliverySetup (newValue) {
        const modalStore = useModalStore();
        // if change delivery area/store
        if (newValue) {
          modalStore.setAlertModalLaterOpen();
        }
      },
      // check delivery modal is currently close the user
      isDeliveryMethodOpened (newValue, oldValue) {
        const modalStore = useModalStore();
        const selectedPostCode = this.$cookies.get('selected_postcode');
        if (
          !newValue &&
          oldValue &&
          (selectedPostCode.closed || modalStore.modals.alertModal.laterOpen) &&
          this.currentPageContainAgeRestrictedProduct
        ) {
          if (this.isEcomUser && this.userAgeConfirmed == null) {
            commitAlcoholConfirmModal(this.$awt);
          } else if (!this.isEcomUser) {
            commitAlcoholAlertModal(this);
          }
        } else {
          modalStore.resetAlertModalLaterOpen();
        }
      },
      // watch ageConfirm modal YES answer
      async 'confirmModal.confirmOk.type' (newValue) {
        const modalStore = useModalStore();
        if (newValue === 'ageConfirm') {
          await this.saveAgeConfirmation(true);
          modalStore.resetConfirmOk();
        }
      },
      // watch ageConfirm modal NO answer
      async 'confirmModal.confirmCancel.type' (newValue) {
        const modalStore = useModalStore();
        if (newValue === 'ageConfirm') {
          await this.saveAgeConfirmation(false);
          modalStore.resetConfirmCancel();
        }
      },
    },
    mounted () {
      const modalStore = useModalStore();
      this.pageLoader = false;

      window.addEventListener('CookiebotOnAccept', this.cookieAcceptance, { once: true });
      window.addEventListener('CookiebotOnDecline', this.cookieDeclination, { once: true });

      if (!this.$cookies.get('startup_popup_closed') && this.startPopup) {
        modalStore.openStartupPopup();
      }
    },
    beforeUnmount () {
      window.removeEventListener('CookiebotOnAccept', this.cookieAcceptance);
      window.removeEventListener('CookiebotOnAccept', this.cookieDeclination);
    },
    methods: {
      async saveAgeConfirmation (status) {
        const userStore = useUserStore();
        const productsStore = useProductsStore();
        const orderStore = useOrderStore();
        try {
          productsStore.setAlcoholProductIsLoading(true);

          // save confirm status
          await this.$api.$put('/me/age_confirmation', {
            confirmed: status,
          });
          // remove NO confirm cookie if user press YES
          if (status) {
            this.$cookies.remove('under18');
          }

          // refresh product list
          if (this.isProductListPage) {
            // set NO confirm to cookie during session if we are on product list page, because on server side not stored this value
            if (!status) {
              this.$cookies.set('under18', true, { path: '/' });
            }
            await productsStore.throttledRefresh();
          }

          // refresh search page list
          if (this.isSearchPage) {
            // set NO confirm to cookie during session if we are on search page, because on server side not stored this value
            if (!status) {
              this.$cookies.set('under18', true, { path: '/' });
            }
            await productsStore.throttledRefresh();
          }

          // refresh product page AND redirect to HOME page if press no
          if (this.isProductDetailsPage) {
            if (!status) {
              await navigateTo(this.localePath('/'));
            }
          }
          productsStore.setAlcoholProductIsLoading(false);

          // refresh user data and shopping lists
          const userResult = await this.$api.$get('/me');
          userStore.setUserData({
            ...userResult,
            shoppingLists: this.user.shoppingLists,
          });

          if (this.isHomePage || this.isShortList) {
            // refresh shopLists
            await this.$nuxt.refresh();
          }

          // if we confirm from profile/orders page
          orderStore.setAgeConfirm(status);
        } catch (error) {
          this.$logger.error(error);
          await setErrorNotification({ nuxtApp: useNuxtApp(), error });
        }
      },
      ...mapActions(useCookieConsentStore, ['setAgreement', 'setMarketing']),

      cookieDeclination () {
        this.setAgreement(false);
        this.setMarketing(false);
      },
      cookieAcceptance () {
        this.setAgreement(true);
        if (window.Cookiebot.consent.marketing) {
          this.setMarketing(true);
        }
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: $color-seashell;
}

.container {
  flex: 1;
  margin-top: 60px;
  padding-bottom: 24px;

  @include md(max) {
    margin-top: 24px;
    padding-bottom: 16px;
  }
}

.overflowHidden {
  overflow: hidden;
}

.sticky {
  position: sticky;
  z-index: 7;
  top: 0;
}

.headerSearch {
  margin: 16px;

  &Wrapper {
    position: relative;
    width: 100%;
    max-width: 288px;
    margin: auto;
  }

  &Bar {
    width: 100%;
    max-width: 288px;
    height: 56px;
    padding: 8px 56px 8px 16px;
    border: 1px solid $color-alto;
    border-radius: 8px;
    background: $color-white;
  }

  &Icon {
    position: absolute;
    top: 16px;
    right: 16px;
    bottom: 16px;
  }
}
</style>
